@use 'src/theme/helpers' as *;
@use 'src/theme/color.module' as *;

$transitionDuration: 200;

.root {
  width: 100%;
}

.accordion {
  border-bottom: pxToRem(1) solid var(--bor-neu-mut);
  padding: pxToRem(8);

  &.expanded {
    .accordionContentWrapper {
      padding-top: pxToRem(8);
      height: auto;
    }

    .accordionIcon {
      transform: rotate(180deg);
    }
  }
}

.accordionTrigger {
  color: var(--tex-def-tie1);
  appearance: none;
  display: block;
  width: 100%;
  text-align: left;
  background: transparent;
  border: none;
  padding: pxToRem(12) pxToRem(16);
  font-family: inherit;
  font-weight: 800;
  cursor: pointer;
  position: relative;
  transition: background 200ms ease-in-out;

  &:focus-visible {
    background: var(--sta-on_bac-pre);
  }
}

.accordionIcon {
  position: absolute;
  top: pxToRem(16);
  right: pxToRem(16);
  transition: transform 300ms;
  display: block;
  height: pxToRem(24);
  width: pxToRem(24);
}

.accordionContentWrapper {
  height: 0;
  overflow: hidden;
}

.accordionContent {
  padding: 0 pxToRem(16) pxToRem(16);

  p,
  ul {
    &:not(:last-child) {
      margin-bottom: pxToRem(16);
    }
  }

  li:not(:last-child) {
    margin-bottom: pxToRem(8);
  }

  ul {
    margin-top: pxToRem(16);
  }
}

:export {
  transitionDuration: $transitionDuration;
}
