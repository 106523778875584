@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  border-radius: var(--cor_rad-xs);
  padding: pxToRem(16);
  background: var(--bac-tie2);
}

.header {
  margin: 0 0 pxToRem(24);
  color: var(--tex-def-tie1);
}

.content {
  display: grid;
}

.lastServiceContainer {
  display: grid;
  grid-template: '🚗 😀' auto / pxToRem(48) 1fr;
  gap: pxToRem(24);
  align-items: center;
  order: 1;
}

.badgeIllustration {
  grid-area: 🚗;
  align-self: flex-start;
}

.serviceDate::after {
  content: '•';
  margin: 0 0 0 pxToRem(8);
  display: inline;
}

.headerContainer {
  grid-area: 😀;
  display: flex;
  flex-direction: column;
  gap: pxToRem(8);
  color: var(--tex-def-tie1);

  p {
    margin: 0;
  }
}

.latestItem {
  display: flex;
  gap: pxToRem(8);
}

.listContainer {
  order: 2;
  :global {
    .MuiButton-root {
      padding: pxToRem(12) auto;
    }
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  order: 3;

  :global {
    .MuiButton-endIcon {
      transition: transform 0.3s ease;
    }
  }
}

.listItem {
  display: flex;
  justify-content: space-between;
  padding: pxToRem(22) 0;
  border-bottom: $border-0;
  color: var(--tex-def-tie1);

  p {
    margin: 0;
  }
}

@include sm {
  .content {
    grid-template-areas:
      'a b'
      'c c';
  }

  .lastServiceContainer {
    grid-area: a;
    order: 1;
  }

  .buttonContainer {
    grid-area: b;
    order: 2;
    justify-content: end;
  }

  .listContainer {
    grid-area: c;
    order: 3;

    :global {
      .MuiButton-root {
        padding: 0;
      }
    }
  }
}
