@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.optionContainer {
  label {
    align-items: center;
    padding: pxToRem(4) pxToRem(8);
    border-radius: var(--cor_rad-md);

    &:hover {
      background-color: var(--sta-ter-hov);
    }

    &:has(input:checked) {
      background-color: var(--sta-sec-hov);
    }
  }
}

.option {
  display: flex;
  width: 100%;
  column-gap: pxToRem(8);
  align-items: center;
  justify-content: space-between;
}
