@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.intro {
  @include fontSize(17, 24);
  margin-bottom: pxToRem(24);

  ul {
    padding: pxToRem(8);
    margin: 0 0 pxToRem(24);
    background: var(--bac-tie1);
    border-radius: var(--cor_rad-xs);
    list-style: none;

    li {
      padding-left: pxToRem(24);
      position: relative;

      &::before {
        content: '•';
        position: absolute;
        top: 0.1em;
        left: 0.6em;
      }
    }
  }

  p {
    margin: 0 0 pxToRem(24);
  }

  p + ul {
    margin-top: pxToRem(-16);
  }
}
