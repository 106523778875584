@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/helpers' as *;

.message {
  @include fontSize(17, 24);
  font-weight: normal;
  padding: pxToRem(16);
  border-radius: var(--cor_rad-xs);
  background: $color-sentimental-error-050;
  color: var(--tex-def-tie1);
  margin: pxToRem(8) 0;
}
