@use 'src/theme/helpers' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/breakpoints.module' as *;

.root {
  --carousel-track-padding: #{pxToRem(16)};
  position: relative;

  :global(.carouselTrack) {
    padding-left: var(--carousel-track-padding);
    padding-right: var(--carousel-track-padding);
  }

  :global(.carouselTile) {
    scroll-snap-align: center;
    flex: 0 0 calc(85vw - #{pxToRem(16)});
    border-radius: var(--cor_rad-lg);
  }

  :global(.carouselControls) {
    &:before,
    &:after {
      display: none;
    }
  }

  .button {
    transition: opacity 200ms ease-in-out;
    opacity: 0;
    cursor: pointer;
    display: none;
  }

  :global(.overflow-right + .carouselControls) {
    .next {
      opacity: 1;
    }
  }

  :global(.overflow-left + .carouselControls) {
    .prev {
      opacity: 1;
    }
  }
}

@include sm {
  .root {
    --carousel-track-padding: #{pxToRem(32)};

    :global(.carouselTile) {
      flex: 0 0 calc(55vw - #{pxToRem(16)});
    }
  }
}

@include md {
  .root {
    :global(.carouselTile) {
      flex: 0 0 calc(30vw - #{pxToRem(16)});
    }
  }
}

@include mdp {
  .root {
    --carousel-track-padding: #{pxToRem(8)};

    :global(.carouselTrack) {
      margin-left: pxToRem(-8);
      margin-right: pxToRem(-8);
    }

    :global(.carouselTile) {
      flex: 0 0 calc(33.333% - #{pxToRem(32/3)});
    }

    .button {
      z-index: 1;
      display: block;
      position: absolute;
      top: calc(50% - #{pxToRem(20)});
      pointer-events: auto;

      &.prev {
        left: pxToRem(-28);
      }

      &.next {
        right: pxToRem(-28);
      }
    }
  }
}

@include lg {
  .root {
    .button {
      &.prev {
        left: pxToRem(-72);
      }

      &.next {
        right: pxToRem(-72);
      }
    }
  }
}
