@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/helpers' as *;

.root:not(.enableLastLink) {
  .item {
    &:last-child {
      pointer-events: none;
    }
  }
}

.list {
  list-style: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  color: var(--ind-900);
}

.item {
  position: relative;

  &:not(:last-child) {
    padding-right: pxToRem(18);

    &:after {
      content: '';
      display: block;
      width: pxToRem(6);
      height: pxToRem(6);
      border-width: thin thin 0 0;
      border-style: solid;
      border-color: inherit;
      transform: translate(20%, -60%) rotate(45deg) skew(5deg, 5deg) scale(0.9);
      position: absolute;
      right: pxToRem(8);
      top: 50%;
    }
  }
}

.link {
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
