@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.headerContainer {
  margin-bottom: pxToRem(8);
}

.title {
  color: var(--tex-def-tie1);
  text-align: center;
  margin: 0;
}

.description {
  color: var(--tex-def-tie2);
  text-align: center;
  max-width: pxToRem(628);
  margin: 0 auto pxToRem(32);
}

.awardsContainer {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: pxToRem(32);
}

.imageContainer {
  margin-left: pxToRem(-8);
}

.image {
  display: block;
}

.buttonContainer {
  text-align: center;
}
