@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  display: block;
  margin: 0;
  width: 100%;
  aspect-ratio: 16 / 9;
  position: relative;
  background: #000;

  iframe {
    display: block;
  }
}

.customImageAndPlayButton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  overflow: hidden;
  padding: 0;
  cursor: pointer;
  border: none;

  //To apply zoom in effect when hover on both button and image
  &:hover,
  &:focus-visible {
    .image {
      transform: scale(1.1);
    }
    .buttonContainer {
      backdrop-filter: blur(pxToRem(5)) brightness(0.75);
    }
  }
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: transform ease-in-out 200ms;
}

.buttonContainer {
  width: pxToRem(80);
  height: pxToRem(80);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  backdrop-filter: blur(pxToRem(5));
  overflow: hidden;
  transition: backdrop-filter ease-in-out 200ms;
}

.setButtonCentered {
  margin-left: auto;
  margin-right: auto;
}

.playButton {
  width: 100%;
  height: 100%;
}

.video {
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border: none;
}

.videoDescription {
  @include fontSize(14, 18);
  color: var(--tex-def-tie2);
  margin-top: pxToRem(8);
}

@include sm {
  .buttonContainer {
    width: pxToRem(104);
    height: pxToRem(104);
  }
}

@include md {
  .buttonContainer {
    width: pxToRem(120);
    height: pxToRem(120);
  }
}
