@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  --section-paddingTop: #{pxToRem(40)};
  display: grid;
  grid-template:
    '😀' auto
    '🚗' auto
    '🎛️' auto / 1fr;
  row-gap: pxToRem(20);
  column-gap: pxToRem(20);

  &:has(:global(.valueProps)) {
    grid-template:
      '😀' auto
      '🚗' auto
      '🎛️' auto
      '🔥' auto / 1fr;
  }

  :global(.valueProps) {
    grid-area: 🔥;
  }

  &:global(.c-se) {
    padding-top: calc(var(--section-paddingTop) + $headerHeight);
  }

  .subtitle {
    color: var(--tex-def-tie2);
    margin-top: pxToRem(16);
  }
}

.header {
  grid-area: 😀;
  align-self: flex-end;
}

.images {
  grid-area: 🚗;
  align-self: center;
}

.imageDesktop {
  display: none;
}

.form {
  grid-area: 🎛️;
  align-self: flex-start;
}

@include md {
  .root {
    --section-paddingTop: #{pxToRem(48)};

    grid-template:
      '😀 🚗' auto
      '🎛️ 🚗' auto / 45fr 55fr;

    &:has(:global(.valueProps)) {
      grid-template:
        '😀 🚗' auto
        '🎛️ 🚗' auto
        '🔥 🔥' auto / 45fr 55fr;
    }
  }

  .imageMobile {
    display: none;
  }
  .imageDesktop {
    display: block;
  }

  .form {
    max-width: pxToRem(480);
  }
}
