@use 'src/theme/helpers' as *;
@use 'src/theme/color.module' as *;

.root {
  display: grid;
  row-gap: pxToRem(16);
  margin-bottom: pxToRem(32);
}

.rating {
  display: flex;
  justify-content: space-between;

  :global {
    .MuiRating-root {
      gap: pxToRem(8);
    }
  }
}

.stars {
  padding-left: 0;
}

.imageContainer {
  display: flex;
  align-items: center;
  position: relative;
  width: pxToRem(114);
  height: pxToRem(24);

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }
}

.info {
  display: grid;
  grid-template-areas:
    'A B'
    'C C';
  align-items: center;
}

.name {
  grid-area: A;
  @include fontSize(17, 24);
  font-weight: 800;
  color: var(--tex-def-tie1);
}

.date {
  grid-area: B;
  justify-self: flex-end;
  @include fontSize(15, 20);
  color: var(--tex-def-tie3);
  padding-right: pxToRem(8);
}

.carType {
  grid-area: C;
  @include fontSize(15, 20);
  color: var(--tex-def-tie2);
  display: flex;
  align-items: flex-end;
  gap: pxToRem(4);
}
