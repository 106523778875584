@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.container {
  max-width: pxToRem(846);
  margin: auto;
  display: grid;
  grid-template:
    '😀' auto
    '🎛️' auto
    '⏰' auto / 1fr;
  column-gap: pxToRem(32);
  row-gap: pxToRem(16);
}

.header {
  grid-area: 😀;
}
.content {
  grid-area: 🎛️;

  h2 {
    margin: pxToRem(24) 0 pxToRem(16);
    @include fontSize(24, 28);
  }

  p {
    margin-bottom: pxToRem(16);
  }

  h2,
  p {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.hoursWrapper {
  grid-area: ⏰;
  margin-top: pxToRem(16);
}

.hours {
  background: var(--for-pri-sub);
  border-radius: var(--cor_rad-lg);
  padding: pxToRem(16);
}

.hoursContent {
  margin: pxToRem(16) 0 0;
}

.hour {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: pxToRem(8);
  margin-bottom: pxToRem(8);

  &:last-child {
    margin-bottom: 0;
  }
}

.hourDay,
.hourTime {
  margin: 0;
}

@include smp {
  .container {
    grid-template:
      '😀 ⏰' auto
      '🎛️ ⏰' auto / 1fr 1fr;
  }
  .hoursWrapper {
    margin-top: 0;
  }
}
