@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  text-align: center;
  display: block;
  align-items: center;
}

.contentWrapper {
  display: grid;
  grid-template:
    '💰' auto
    '🔍' auto
    '🌅' auto
    '🎛' auto;

  :nth-child(1) {
    grid-area: 💰;
  }

  :nth-child(2) {
    grid-area: 🔍;
  }

  :nth-child(3) {
    grid-area: 🌅;
    margin-top: pxToRem(32);
  }

  :nth-child(4) {
    grid-area: 🎛;
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: pxToRem(24);
  margin-bottom: pxToRem(32);
  text-align: center;
}

.baseHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: pxToRem(8);
  border-radius: var(--cor_rad-xs);
  padding: pxToRem(16);
  margin-bottom: pxToRem(8);
  text-align: left;

  &.venetianRed {
    background: $color-brandPrimary-100;
  }

  &.flashWhite {
    background: $color-neutral-100;
  }

  &.white {
    color: $color-opacity-light-highEmphasis;
  }

  .title {
    text-wrap: pretty;
  }
}

.bodyContent {
  padding: 0 pxToRem(16);
}

.itemsWrapper {
  display: flex;
  flex-direction: column;
  gap: pxToRem(8);
  padding-bottom: pxToRem(24);
}

.itemContent {
  .headerItem {
    display: flex;
    align-items: center;
    gap: pxToRem(8);
    align-self: stretch;

    .title {
      display: flex;
      align-items: center;
      margin: pxToRem(6) 0;
      gap: pxToRem(8);
      flex: 1 0 0;
    }

    span {
      width: pxToRem(32);
      height: pxToRem(32);
    }
  }

  .valueItem {
    padding-left: pxToRem(28);
    text-align: start;
  }
}

.breakDownContent {
  border-top: $border-0;
  padding-top: pxToRem(24);
  display: flex;
  flex-direction: column;
  gap: pxToRem(8);

  .breakDownHeader {
    letter-spacing: pxToRem(1.2);
    text-transform: uppercase;
    text-align: start;
  }

  span {
    width: pxToRem(32);
    height: pxToRem(32);
  }
}

.breakDownItem {
  display: flex;
  align-items: center;
  gap: pxToRem(8);
  flex: 1 0 0;

  .title {
    display: flex;
    align-items: center;
    gap: pxToRem(8);
    flex: 1 0 0;
    padding: pxToRem(6) 0;

    .iconCancel {
      width: pxToRem(20);
      height: pxToRem(20);
      padding: pxToRem(5);
    }
  }
}

.iconBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  width: pxToRem(20);
  height: pxToRem(20);
}

.iconWrapper {
  height: pxToRem(32);
}

.iconExclamation {
  width: pxToRem(32);
  height: pxToRem(32);
  padding: pxToRem(8.5);
}

.tooltip {
  border-radius: pxToRem(5);
  padding: pxToRem(16);
  color: $color-opacity-light-highEmphasis;
  background: $color-uiPrimary-900;
  width: pxToRem(292);

  p {
    margin-bottom: pxToRem(8);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.headline {
  font-weight: 800;
}

.blockButton {
  margin-left: auto;
}

.buttonClose {
  width: fit-content;
  padding: pxToRem(11.5) pxToRem(12);
  border-radius: var(--cor_rad-xs);
  border: $border-2;
  @include fontSize(15, 18);
  font-weight: 600;
}

.mobileTooltip {
  display: inline-block;
}

.desktopTooltip {
  display: none;
}

@include md {
  .root {
    max-width: pxToRem(960);
  }

  .contentWrapper {
    grid-template:
      '💰 🌅' auto
      '🔍 🎛' auto / 1fr 1fr;
    gap: 0 pxToRem(20);

    :nth-child(3) {
      margin-top: 0;
    }
  }

  .mobileTooltip {
    display: none;
  }

  .desktopTooltip {
    display: inline-block;
  }
}

@include lg {
  .root {
    max-width: pxToRem(846);
  }
}

@include xl {
  .root {
    max-width: pxToRem(1060);
  }
}
