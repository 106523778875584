@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  display: inline-block; // inline-block prevents margin from collapsing.
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border: none;
  width: 100%;
  height: pxToRem(1);
  background-color: $color-neutral-300;
  margin: pxToRem(16) 0;
  max-width: calc(100% - pxToRem(2 * 20));
}

@include sm {
  .root {
    margin: pxToRem(24) 0;
    max-width: min(pxToRem(715), calc(100% - pxToRem(2 * 32)));
  }
}

@include lg {
  .root {
    margin: pxToRem(32) 0;
    max-width: pxToRem(848);
  }
}

@include xl {
  .root {
    max-width: pxToRem(1060);
  }
}
