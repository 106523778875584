@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: var(--elevation-2dp);
  color: var(--tex-def-tie1);
  overflow: visible;
  position: relative;

  .close {
    position: absolute;
    right: pxToRem(20);
    top: pxToRem(20);
  }
}

.modal {
  &::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.wrapper {
  padding: pxToRem(20);
  overflow-x: hidden;
  overflow-y: auto;
  max-width: 100%;
  max-height: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  margin: 0;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: pxToRem(16);
}

@include sm {
  .root {
    margin: auto;
    width: fit-content;
    height: fit-content;
    border-radius: var(--cor_rad-lg);
  }
}
