@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: pxToRem(8);
  column-gap: pxToRem(16);
  margin-top: pxToRem(24);
}

@include md {
  .root {
    grid-template-columns: 1fr 1fr;
  }
}
