@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  padding: 0;
  aspect-ratio: 4/5;
  position: relative;

  &.light {
    color: var(--tex-inv-tie1);
  }
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  position: relative;
  @include sectionHorizontalSpacing();
}

@include sm {
  .root {
    aspect-ratio: 1920/822;
  }
}

@include xl {
  .root {
    aspect-ratio: initial;
    width: 100%;
    height: pxToRem(822);
  }
}
