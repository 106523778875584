@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.header {
  color: var(--tex-def-tie1);
  margin: 0;
}

.content {
  color: var(--tex-def-tie2);

  // Styling for markdown content.
  * {
    margin: 0;
    padding-top: pxToRem(16);
  }

  li {
    padding: 0;
  }

  h4,
  h5,
  h6 {
    @include fontSize(17, 24);

    &:not(:first-child) {
      padding-top: pxToRem(48);
    }

    & + * {
      padding-top: pxToRem(12);
    }
  }

  a {
    font-weight: 800;
    color: $color-uiPrimary-600;
  }
}

@include sm {
  // The Figma designs state that text should always be left-aligned at the XS breakpoint, so this class will only
  // take effect from SM and up.
  .textAlignCenter {
    text-align: center;

    // Lists always remain left-aligned.
    ul,
    ol {
      text-align: left;
    }
  }
}
