@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.optionWithIcon {
  position: relative;
  display: block;
  padding-right: pxToRem(48);

  :global(.icon) {
    position: absolute;
    font-size: pxToRem(40);
    top: pxToRem(-8);
    right: pxToRem(4);
  }
}
