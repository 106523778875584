@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  padding-bottom: pxToRem(24);
  display: grid;
  grid-template:
    '😀 🚗' auto
    '👤 🚗' auto / 1fr pxToRem(40);
  column-gap: pxToRem(8);

  &:not(:has(.subtitle)) {
    grid-template: '😀 🚗' auto / 1fr pxToRem(40);
  }
}

.header {
  grid-area: 😀;
  align-self: center;
}

.suptitle {
  @include fontSize(12, 16);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 900;
  color: var(--tex-def-tie3);
  margin-bottom: pxToRem(8);
}

.title {
  margin: 0;
  color: var(--tex-def-tie1);
}

.subtitle {
  @include fontSize(14, 18);
  font-weight: normal;
  letter-spacing: pxToRem(0.2);
  margin: 0;
  color: var(--tex-def-tie2);
  grid-area: 👤;
  margin-top: pxToRem(8);
}

.buttonWrapper {
  grid-area: 🚗;
  align-self: top;
}

@include md {
  .subtitle {
    @include fontSize(15, 20);
  }
}
