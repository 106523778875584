@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  padding: $headerHeight 0;
  aspect-ratio: 4/5;
  position: relative;

  &.left .contentWrapper {
    justify-content: flex-start;
  }
  &.centre .contentWrapper {
    justify-content: center;
    text-align: center;

    :global(.HeroContent-ctaContainer) {
      justify-content: center;
    }
  }

  &.light {
    color: var(--tex-inv-tie1);
  }
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contentWrapper {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  @include sectionHorizontalSpacing();

  :global {
    .HeroContent {
      max-width: pxToRem(600);
    }

    .HeroContent-headerContainer {
      margin: pxToRem(16) 0;
    }

    .HeroContent-ctaContainer {
      margin-top: pxToRem(20);
      display: flex;
      column-gap: pxToRem(8);
    }
  }
}

@include smp {
  .root {
    aspect-ratio: 16/9;
  }
}

@include lg {
  .contentWrapper {
    :global {
      .HeroContent-ctaContainer {
        margin-top: pxToRem(40);
      }
    }
  }
}
