@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  @include rootSection(var(--bac-tie2));
  padding-top: calc(#{pxToRem(32)} + $headerHeight);
  padding-bottom: pxToRem(32);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: pxToRem(32);
  width: 100%;
  position: relative;
}

.actionContainer {
  bottom: pxToRem(16);
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  z-index: 1;
}

:global(body:has(.carComparisonBanner)) {
  .actionContainer {
    bottom: pxToRem(72);
  }
}

@include md {
  .actionContainer {
    position: absolute;
    right: pxToRem(0);
    bottom: pxToRem(0);
    left: auto;
    transform: none;
  }
}
