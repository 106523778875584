@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  @include fontSize(17, 27);
  color: var(--tex-def-tie1);
}

// Remove all browser default margins.
.title,
.authorName {
  margin: 0;
}

.title {
  text-align: center;
  padding-bottom: pxToRem(40);
}

.author {
  display: grid;
  gap: pxToRem(4) pxToRem(16);
  grid-template-columns: auto 1fr;
  grid-template-rows: pxToRem(24) pxToRem(20);
  grid-template-areas:
    'image name'
    'image name';
  padding-bottom: pxToRem(31);
}

.authorImageWrapper {
  grid-area: image;
  overflow: hidden;
  border-radius: 50%;
}

.authorName {
  grid-area: name;
}

.content {
  column-gap: pxToRem(80);

  & > * {
    margin-bottom: pxToRem(24);

    &:last-child {
      margin-bottom: 0;
    }
  }

  :global {
    .h1,
    .h2,
    .h3,
    .h4 {
      text-wrap: pretty;
    }

    .h1 {
      @include fontSize(26, 32);
    }

    .h2,
    .h3,
    .h4 {
      @include fontSize(20, 28);
    }
  }

  ul,
  ol {
    padding-left: pxToRem(20);
  }

  // Quote styles
  :global(.quote) {
    @include sectionVerticalSpacing();
    margin-left: 0;
    margin-right: 0;
    padding-left: pxToRem(36);
    border-left: pxToRem(4) solid $color-neutral-200;
  }
  :global(.quote-content) {
    margin: 0;
  }
  :global(.quote-author) {
    @include fontSize(17, 24);
    margin: pxToRem(12) 0 0;
  }
  blockquote {
    @include fontSize(32, 40);
    font-style: italic;

    :first-child:before {
      content: '“';
      display: inline-block;
      margin-left: -0.4em;
    }

    :last-child:after {
      content: '”';
    }
  }
}

@include md {
  .columns {
    &-2,
    &-3 {
      columns: 2;
    }
  }

  .content {
    :global {
      .h1 {
        @include fontSize(32, 40);
      }

      .h2 {
        @include fontSize(26, 32);
      }

      .h3,
      .h4 {
        @include fontSize(20, 28);
      }
    }
  }
}

@include lg {
  .columns-3 {
    columns: 3;
  }
  .content {
    :global {
      .h1 {
        @include fontSize(44, 52);
      }

      .h2 {
        @include fontSize(32, 40);
      }

      .h3 {
        @include fontSize(26, 32);
      }

      .h4 {
        @include fontSize(20, 28);
      }
    }
  }
}
