@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  margin: pxToRem(32) 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

@include md {
  .root {
    margin: pxToRem(44) 0;
  }
}
