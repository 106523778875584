@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/helpers' as *;

.root {
  padding: 0;
  @include fontSize(17, 24);
}

.deliveredWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.deliveredTitle {
  margin-top: pxToRem(2.5);
}

.titleWrapper {
  padding-top: pxToRem(16);
}

.titleIcon {
  margin-right: pxToRem(16);
}

.bold {
  font-weight: 800;
}

.title {
  @include fontSize(20, 28);
}

.flex {
  display: flex;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.smallText {
  @include fontSize(15, 20);
}

.directionsButton {
  padding-top: pxToRem(16);
}

@include md {
  .root {
    padding: pxToRem(16);
  }

  .titleWrapper {
    padding-top: pxToRem(16);
  }
}
