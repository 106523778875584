@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.grid {
  display: flex;
  flex-direction: column;
  gap: pxToRem(24);
  width: 100%;
  justify-content: center;

  .title {
    margin-bottom: pxToRem(24);
  }
}

.heroImageMobile {
  width: 100%;
  height: 100%;
  max-width: pxToRem(335);
  max-height: pxToRem(208);
  object-fit: cover;
  place-self: center;
}

.heroImageDesktop {
  width: 100%;
  height: 100%;
  max-width: pxToRem(335);
  max-height: pxToRem(208);
  object-fit: cover;
  place-self: center;
  display: none;
}

.stepsColumn {
  place-self: center;
}

.steps {
  position: relative;
  display: flex;
  flex-direction: column;
}

.step {
  display: grid;
  grid-template-columns: pxToRem(56) 1fr;
  gap: pxToRem(16);
  z-index: 1;
  padding-bottom: pxToRem(32);
}

.stepIconContainer {
  display: grid;
  grid-template-rows: pxToRem(56) 1fr;
}

.stepIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: pxToRem(56);
  height: pxToRem(56);
  border: pxToRem(2) solid #aebdc5;
  border-radius: pxToRem(32);
}

.stepConnector {
  width: pxToRem(2);
  height: calc(100% + 32px);
  background-color: $color-neutral-400;
  place-self: center;
  margin-bottom: pxToRem(-32);
}

.stepTitle {
  color: var(--tex-def-tie1);
  padding-bottom: pxToRem(8);
}

@include sm {
  .heroImageMobile {
    max-width: pxToRem(704);
    max-height: pxToRem(438);
  }
}

@include md {
  .heroImageMobile {
    display: none;
  }

  .heroImageDesktop {
    display: block;
    max-width: pxToRem(470);
    max-height: pxToRem(624);
  }

  .grid {
    flex-direction: row;
    gap: pxToRem(20);

    .title {
      margin-bottom: pxToRem(40);
    }
  }
}

@include lg {
  .grid {
    gap: pxToRem(128);
  }
  .heroImageDesktop {
    max-width: pxToRem(522);
    max-height: pxToRem(693);
  }
}

@include xl {
  .grid {
    gap: pxToRem(153);
  }

  .heroImageDesktop {
    max-width: pxToRem(655);
    max-height: pxToRem(869);
  }
}
