@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.option {
  padding: pxToRem(16);
  box-shadow:
    var(--elevation-2dp),
    inset 0 0 0 pxToRem(1) var(--bor-neu-mut);
  border-radius: var(--cor_rad-md);
  overflow: hidden;
  position: relative;
  appearance: none;
  text-align: left;
  display: block;
  border: none;
  background: var(--bac-tie1);
  font-family: inherit;
  transition: box-shadow ease-in-out 200ms;
  cursor: pointer;

  :global(.shade:before) {
    display: none;
  }

  &:hover,
  &:focus-visible,
  &:has(:focus-visible) {
    box-shadow:
      var(--elevation-8dp),
      inset 0 0 0 pxToRem(2) var(--bor-neu-bas);
  }
}

.tileOption {
  display: grid;
  grid-template-columns: pxToRem(134) auto;
  padding: pxToRem(6) 0 pxToRem(6) pxToRem(6);

  .imageContainer {
    position: relative;
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: var(--cor_rad-xs);
    overflow: hidden;
    pointer-events: none;
  }

  .image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 85%;
  }

  .textContainer {
    padding: pxToRem(10) pxToRem(16);
    pointer-events: none;
  }
}

.title {
  @include fontSize(17, 24);
  font-weight: 800;
  color: var(--tex-def-tie1);
}

.subtitle {
  @include fontSize(14, 18);
  font-weight: 400;
  letter-spacing: pxToRem(0.2);
  color: var(--tex-def-tie2);
}

@include md {
  .tileOption {
    display: flex;
    flex-direction: column;
    padding: pxToRem(10) pxToRem(10) 0 pxToRem(10);

    .imageContainer {
      aspect-ratio: 16 / 9;
      border-radius: pxToRem(6);
    }

    .textContainer {
      padding: pxToRem(16) pxToRem(6);
    }
  }

  .title {
    @include fontSize(17, 24);
  }
}
