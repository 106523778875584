@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  :global(.tabs) {
    --tablist-offset: #{pxToRem(20)};
  }

  :global(.tabs-tablistWrapper) {
    margin-bottom: pxToRem(26);
  }
}

.header {
  margin-bottom: pxToRem(24);
}

@include sm {
  .root {
    :global(.tabs) {
      --tablist-offset: #{pxToRem(32)};
    }
  }
}

@include xl {
  .root {
    :global(.tabs) {
      --tablist-offset: #{pxToRem(64)};
    }
  }
}
