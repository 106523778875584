@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

$option-active-box-shadow:
  0 0 0 rgba(0, 0, 0, 0.04),
  0 pxToRem(2) pxToRem(8) rgba(0, 0, 0, 0.16),
  inset 0 0 0 0.125rem $color-uiPrimary-600;
$option-hover-box-shadow:
  0 0 0 rgba(0, 0, 0, 0.04),
  0 pxToRem(2) pxToRem(8) rgba(0, 0, 0, 0.16),
  inset 0 0 0 0.0625rem $color-uiPrimary-300;
$option-box-shadow:
  0 pxToRem(2) pxToRem(8) 0 rgba(0, 0, 0, 0.16),
  0 0 0 0 rgba(0, 0, 0, 0.04);

.root {
  max-width: pxToRem(560);
  padding: pxToRem(20) 0 0;
  margin: auto;

  .header {
    padding-bottom: pxToRem(36);
  }
}

.content {
  display: grid;
  row-gap: pxToRem(16);
}

.successMessage {
  @include fontSize(17, 24);
  font-weight: 800;
  text-align: center;

  max-width: pxToRem(420);
  margin: auto;
}
.successMessageIllustrationWrapper {
  margin: pxToRem(-40) auto pxToRem(32);
}
.successMessageIllustrationWrapper,
.successMessageIllustration {
  width: pxToRem(240);
  height: pxToRem(240);
  display: block;
}

.successFooter {
  margin-top: pxToRem(80);
}

@include md {
  .root {
    padding-top: pxToRem(28);
  }

  .rootTiles {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: pxToRem(16);
    column-gap: pxToRem(16);
  }

  .title {
    @include fontSize(17, 24);
  }
}
