@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/zindexes.module' as *;

.check:global(.icon) {
  color: var(--ico-suc-tie1);
  font-size: pxToRem(18);
  display: block;
}
