@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/zindexes.module' as *;

.root {
  border: $border-0;
  border-radius: var(--cor_rad-xs);
  position: relative;
  overflow: hidden;
}

.row {
  border-top: $border-0;

  &:first-child {
    border-top: none;
  }
}

.title {
  background: var(--bac-tie2);
  border-bottom: $border-0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: pxToRem(4);
}

.titleIcon {
  display: none;

  :global(.icon) {
    display: block;
    margin: pxToRem(-2) 0;
  }
}

.values {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  + .values {
    border-top: $border-0;
  }
}

.value {
  padding: pxToRem(6) pxToRem(8);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  border-left: $border-0;

  &:first-child {
    border-left: none;
  }
}

@include md {
  .row {
    display: grid;
    grid-template-columns: 1fr 2fr;

    .title,
    .values {
      @include fontSize(15, 20);
    }
  }

  .title {
    padding: pxToRem(8) pxToRem(16);
    border-bottom: none;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: pxToRem(8);
  }

  .titleIcon {
    display: block;
  }

  .values {
    column-gap: pxToRem(6);
  }

  .value {
    padding: pxToRem(8) pxToRem(16);
    text-align: left;
    align-items: flex-start;
  }
}
