@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.header {
  margin: pxToRem(32) 0 pxToRem(40);
}

@include md {
  .header {
    margin-top: pxToRem(24);
  }
}
