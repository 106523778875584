@use 'src/theme/color.module' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/helpers' as *;

$borderSize: pxToRem(2);

.root {
  --tablist-offset: 0;
}

.tablistWrapper {
  width: 100%;
  position: relative;
  padding-bottom: $borderSize;
}

.tablist {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-left: var(--tablist-offset);
  padding-right: var(--tablist-offset);
  margin-left: calc(-1 * var(--tablist-offset));
  margin-right: calc(-1 * var(--tablist-offset));
  padding-bottom: $borderSize;
  margin-bottom: -$borderSize;
  scroll-snap-type: x mandatory;
  overflow-y: hidden;

  /* Enable Safari touch scrolling physics which is needed for scroll snap */
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* Hide scrollbar on IE and Edge */
  scrollbar-width: none; /* Hide scrollbar on Firefox */

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar on Chrome, Safari and Opera */
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    height: $borderSize;
    left: 0;
    width: 100%;
    background: var(--bor-neu-bas);
  }

  &:global(.overflow-right) {
    + .tablistOverflow:after {
      opacity: 1;
    }
  }

  &:global(.overflow-left) {
    + .tablistOverflow:before {
      opacity: 1;
    }
  }
}

.tablistOverflow {
  pointer-events: none;
  position: absolute;
  top: 0;
  height: 100%;
  left: calc(-1 * var(--tablist-offset));
  right: calc(-1 * var(--tablist-offset));

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: pxToRem(36);
    transition: opacity 200ms ease-in-out;
    opacity: 0;
  }

  &:before {
    left: pxToRem(-2);
    // Using straight black and white for the
    background: linear-gradient(
      90deg,
      var(--section-background, #{$color-neutral-000}) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &:after {
    right: pxToRem(-2);
    // Using straight black and white for the
    background: linear-gradient(
      270deg,
      var(--section-background, #{$color-neutral-000}) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.tab {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: pxToRem(14) pxToRem(16);
  appearance: none;
  border: none;
  background: transparent;
  font-family: inherit;
  font-weight: normal;
  cursor: pointer;
  color: inherit;
  white-space: nowrap;
  scroll-snap-align: center;

  &.tabActive {
    font-weight: bold;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: pxToRem(-2);
    height: $borderSize;
    left: 0;
    width: 100%;
    background: var(--bor-neu-bas);
  }
}

.tabIcon {
  display: block;

  img {
    display: block;
  }
}

.tabUnderline {
  position: absolute;
  bottom: pxToRem(-2);
  left: 0;
  right: 0;
  height: $borderSize;
  background: var(--bor-pri-bas);
}

@include sm {
  .tablistOverflow {
    &::before,
    &::after {
      width: pxToRem(64);
    }
  }
}

@include md {
  .tablistOverflow {
    &:before,
    &:after {
      width: pxToRem(72);
    }
  }
}
