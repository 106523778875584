@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  display: flex;
  gap: pxToRem(36);
  align-items: center;
  flex-direction: column;
  color: var(--tex-def-tie1);
}

.header,
.accordion,
.footer {
  max-width: pxToRem(846);
}

.title {
  margin: 0;
  text-align: center;
}

@include sm {
  .root {
    gap: pxToRem(40);
  }
}

@include md {
  .root {
    gap: pxToRem(48);
  }
}

@include xl {
  .root {
    gap: pxToRem(56);
  }
}
