@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.valueProps {
  border-top: $border-0;
  padding: pxToRem(20) 0 0 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  column-gap: pxToRem(16);
  row-gap: pxToRem(24);
  margin: 0;
}

.valuePropItem {
  --image-size: #{pxToRem(40)};
  display: grid;
  grid-template: '🐱 🐭' auto / var(--image-size) 1fr;
  column-gap: pxToRem(24);

  &:has(.valuePropDescription) {
    grid-template:
      '🐱 🐭' min-content
      '🐱 🐹' auto / var(--image-size) 1fr;
  }
}

.valuePropImage {
  grid-area: 🐱;
  display: block;
  width: var(--image-size);
  height: var(--image-size);
  align-self: flex-start;
}

.valuePropTitle {
  grid-area: 🐭;
  align-self: center;
}

.valuePropDescription {
  grid-area: 🐹;
  align-self: flex-start;
  color: var(--tex-def-tie2);
}

@include md {
  .valueProps {
    flex-direction: row;
    justify-content: space-between;
    padding-top: pxToRem(32);
  }

  .valuePropItem {
    --image-size: #{pxToRem(56)};
    flex: 1;
  }
}
