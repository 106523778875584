@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;

/* style color for microcopy Link */
.link {
  color: inherit;
}

/* For MarkdownInstagram */
.instagramContainer {
  text-align: center;
}

.instagram {
  max-width: pxToRem(400);
  width: calc(100% - pxToRem(2));
  border-radius: pxToRem(3);
  border: pxToRem(1) solid rgb(219, 219, 219);
  box-shadow: none;
  min-width: pxToRem(326);
  height: auto;
  /* For safari */
  min-height: pxToRem(600);
}

.postHeight {
  aspect-ratio: 0.76;
  max-height: pxToRem(560);
}

.reelHeight {
  aspect-ratio: 0.53;
  max-height: pxToRem(860);
}

@include sm {
  .instagram {
    min-height: pxToRem(720);
  }

  .postHeight {
    aspect-ratio: 0.83;
    max-height: fit-content;
  }

  .reelHeight {
    aspect-ratio: 0.56;
    max-height: fit-content;
  }
}

@include xl {
  .instagram {
    min-height: pxToRem(865);
    max-width: pxToRem(540);
  }

  .postHeight {
    aspect-ratio: 0.95;
  }

  .reelHeight {
    aspect-ratio: 0.6;
  }
}
/* end for MarkdownInstagram */
