@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  p {
    margin: 0;
  }
}

.header {
  margin: 0 0 pxToRem(24);
  font-weight: 800;
  @include fontSize(26, 32);
  color: var(--tex-def-tie1);
}

.divider {
  width: 100%;
  margin: pxToRem(30) 0;
  border-top: $border-0;
}

.layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: pxToRem(16);
  row-gap: pxToRem(16);
}

.info {
  display: grid;
  grid-template-columns: pxToRem(56) auto;
  column-gap: pxToRem(12);

  .badge {
    width: pxToRem(56);
    height: pxToRem(56);
  }
}

.description {
  color: var(--tex-def-tie1);
  display: flex;
  align-items: center;
}

@include md {
  .root {
    margin-left: pxToRem(8);
  }

  .layout {
    grid-template-columns: repeat(3, 1fr);
    column-gap: pxToRem(20);
    row-gap: pxToRem(20);
  }

  .info {
    grid-template-columns: pxToRem(56) auto;
  }
}
