@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  display: grid;
  grid-template: '🎛️ ⬆️' auto / auto auto;
  column-gap: pxToRem(16);
  transition-property: all;
  transition-duration: 500ms;

  :global {
    .PlpFiltersTrigger {
      grid-area: 🎛️;
      padding-top: pxToRem(8);
      padding-bottom: pxToRem(8);
      height: pxToRem(38);
      width: fit-content;
    }
    .PlpSort {
      grid-area: ⬆️;
    }
    .PlpSort-select {
      width: 100%;
    }
  }
}

@include sm {
  .root {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .PlpFiltersTrigger {
      // Visually balance out the PlpSort select
      width: fit-content;
    }
  }
}

@include md {
  .root {
    :global(.PlpFiltersTrigger) {
      min-width: 0;
    }
  }
}

@include mdp {
  .root {
    display: block;

    :global(.PlpFiltersTrigger) {
      display: none;
    }
  }
}
