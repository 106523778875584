@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.privacy {
  @include fontSize(14, 18);
  letter-spacing: pxToRem(0.2);
  margin-bottom: pxToRem(32);

  a {
    font-weight: 800;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
