@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  color: var(--tex-def-tie1);

  :global(.RichText) & {
    padding-left: 0;
    padding-right: 0;
  }

  :global(.media) {
    position: relative;
  }

  :global(.mediaMultiple) {
    display: grid;
    grid-template-columns: 1fr;
    gap: pxToRem(20);
  }
}

.header {
  margin-bottom: pxToRem(24);
}

.subTitle {
  @include fontSize(17, 24);
  font-weight: 400;
  margin: 0;
}

.mediaContainer {
  display: block;
  margin: 0;
  position: relative;
}

.mediaDescription {
  @include fontSize(14, 18);
  font-weight: 400;
  color: var(--tex-def-tie2);
  margin-top: pxToRem(8);
}

@include sm {
  .root :global(.mediaMultiple) {
    grid-template-columns: 1fr 1fr;
  }
}
