@use 'src/theme/helpers' as *;
@use 'src/theme/color.module.scss' as *;
@use 'src/theme/zindexes.module.scss' as *;

.root {
  overflow: auto;
  border-radius: var(--cor_rad-lg);
  box-shadow: var(--elevation-2dp);
  background-color: var(--bac-tie1);
  z-index: $zIndex-modal;
}

.listItem {
  padding: pxToRem(10) pxToRem(16);
  display: flex;
  align-items: center;
  gap: pxToRem(16);
}

.listItemActive {
  background-color: var(--for-neu-tie3);
}
