@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  .applePayBlock {
    .paymentLabel {
      letter-spacing: pxToRem(1.2);
      margin: pxToRem(24) 0 pxToRem(12) 0;
    }

    .loadingButton {
      background-color: $color-uiPrimary-000;
      height: pxToRem(44);
      border-radius: pxToRem(5);
    }

    .hideButton {
      display: none;
      pointer-events: none;
    }
  }
}

.loading {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: pxToRem(420);

  .circularProgress {
    width: pxToRem(58);
    height: pxToRem(58);
  }

  span {
    padding-top: pxToRem(12);
    @include fontSize(15, 20);
  }
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: pxToRem(24);
}

.sslLabel {
  gap: pxToRem(8);
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: pxToRem(14);
  align-items: center;
  justify-content: center;

  svg {
    width: pxToRem(28);
    height: pxToRem(28);
  }
}

.paymentLabelRow {
  display: flex;
  width: 100%;
  gap: 10px;
}

.paymentLabel {
  font-weight: bold;
  font-size: pxToRem(17);
  flex-grow: 1;
}

.cardPayment {
  padding-bottom: pxToRem(20);
  width: 100%;
}

.errorText {
  align-items: center;
  color: var(--tex-err-tie1);
  display: flex;
  font-size: pxToRem(14);
  font-weight: 400;
  margin-top: pxToRem(4);
}

.separator {
  margin: 0;
  padding: 0;
  border: none;
  border-top: $border-0;
}

.applePayContainer {
  button {
    width: 100%;
  }
}

@include sm {
  .footer {
    flex-direction: row;

    button {
      width: pxToRem(360);
    }
  }
}

@supports not (-webkit-appearance: -apple-pay-button) {
  .applePayBlock {
    display: none;
  }
}
