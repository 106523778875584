@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  .divider {
    margin-bottom: pxToRem(24);
  }
}

.header {
  margin: 0 0 pxToRem(20);
  font-weight: 800;
  @include fontSize(26, 32);
  color: var(--tex-def-tie1);
}

.content {
  display: grid;
  grid-template:
    '🌅 🌅' pxToRem(224)
    '🐶 🐱' pxToRem(108) / 1fr 1fr;
  gap: pxToRem(16);
}

.triggerGallery {
  color: var(--tex-def-tie1);
  grid-area: 🌅;
}

.triggerImperfection,
.noImperfection {
  color: var(--tex-def-tie1);
  grid-area: 🐶;
}

.triggerInterior {
  color: var(--tex-def-tie1);
  grid-area: 🐱;
}

.noImperfection {
  background: var(--bac-tie2);
  border-radius: var(--cor_rad-xs);
  padding: pxToRem(8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  @include fontSize(15, 18);

  :global {
    .icon {
      display: none;
    }
  }

  h4 {
    margin: pxToRem(8) 0;
    font-weight: 400;
    @include fontSize(15, 18);
  }

  a {
    font-weight: 600;
  }
}

.item {
  position: relative;
  appearance: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: var(--cor_rad-xs);
  overflow: hidden;
  cursor: pointer;
  @include fontSize(15, 18);
  font-family: inherit;
  font-weight: 600;

  &:focus-visible,
  &:hover {
    outline: none;
    .itemImage {
      transform: scale(1.1);
    }

    .itemDescription {
      background: $color-uiPrimary-050;
    }
  }
}

.itemImage {
  object-fit: cover;
  transition: transform ease-out 200ms;
  will-change: transform;
  border-radius: var(--cor_rad-xs);
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.triggerInterior .itemImage {
  object-fit: none;
}

.itemDescription {
  position: absolute;
  bottom: pxToRem(8);
  left: pxToRem(8);
  background: $color-opacity-light-mediumEmphasis;
  border-radius: var(--cor_rad-xs);
  padding: pxToRem(12) pxToRem(12) pxToRem(10);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: background ease-in-out 100ms;
}

.itemIcon {
  display: block;
  font-size: pxToRem(20);
  height: 1em;
  width: 1em;
  margin-right: pxToRem(6);
  margin-top: pxToRem(-2);
  margin-left: pxToRem(-6);

  :global {
    .icon {
      font-size: inherit;
    }
  }
}

.mdUp {
  display: none;
}

@include sm {
  .header,
  .triggerInterior,
  .divider {
    display: none;
  }

  .root,
  .content {
    height: 100%;
  }

  .content {
    grid-template:
      '🌅' 1fr
      '🐶' 1fr / 1fr;
  }

  .itemDescription {
    bottom: pxToRem(16);
    left: pxToRem(16);
  }

  .noImperfection {
    @include fontSize(17, 24);

    h4 {
      @include fontSize(17, 24);
    }

    :global {
      .icon {
        display: block;
      }
    }
  }
}

@include md {
  .mdUp {
    display: block;
  }
  .mdDown {
    display: none;
  }
}

@include mdp {
  .content {
    grid-template: '🌅 🐶' pxToRem(140) / 1fr 1fr;
  }
}

@include lg {
  .content {
    grid-template: '🌅 🐶' pxToRem(172) / 1fr 1fr;
  }

  .noImperfection {
    h4 {
      font-weight: 800;
    }

    h4 + p {
      display: block;
      margin-bottom: pxToRem(2);
    }
  }
}
