@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  padding-top: pxToRem(32);
  padding-bottom: pxToRem(32);
  @include sectionHorizontalSpacing();
  @include fullWidthBackgroundColor(var(--bac-tie1));
}

.header {
  text-align: center;
}

.title {
  margin: 0 0 pxToRem(16);
}

.description {
  color: var(--tex-def-tie2);
  margin: auto;
  max-width: pxToRem(626);
}

.list {
  padding: pxToRem(40) 0 pxToRem(60);
  margin: 0;
}

.item {
  display: inline-flex;
  width: 100%;
  border-bottom: $border-0;
  align-items: center;
  justify-content: space-between;
  padding: pxToRem(24) 0;
}

.itemTerm {
  display: flex;
  align-items: center;

  :global {
    .icon {
      margin-right: pxToRem(12);
      font-size: pxToRem(24);
    }
  }
}

.itemValue {
  color: var(--for-suc-bas);
}

.footer {
  text-align: center;
}

@include md {
  .root {
    padding-top: pxToRem(56);
    padding-bottom: pxToRem(56);
  }

  .list {
    columns: 2;
    column-gap: pxToRem(32);
  }
}

@include lg {
  .list {
    column-gap: pxToRem(48);
  }
}
