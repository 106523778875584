@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  background: #0b051d66;
  color: var(--tex-inv-tie1);
  padding: pxToRem(16);
  border-radius: var(--cor_rad-lg);

  .search .searchTrigger {
    background: var(--bac-tie1);
    border-radius: var(--cor_rad-xs);
    width: 100%;
    height: pxToRem(64);
    display: flex;
    justify-content: space-between;
    padding: pxToRem(16) pxToRem(20) pxToRem(16) pxToRem(16);

    &::before {
      content: 'Search makes, models, body...';
      color: var(--tex-def-tie1);
      @include fontSize(17, 24);
      font-weight: 400;
      opacity: 0.7;
    }
  }

  .searchForm {
    display: none;
  }
}

.header {
  margin-bottom: pxToRem(24);
}

.search {
  min-height: pxToRem(64);
  position: relative;
}

.break {
  &:before {
    content: 'or';
  }
}

.footer {
  margin-top: pxToRem(8);
  text-align: center;

  a {
    color: inherit;

    &:hover,
    &:focus-visible {
      text-decoration: none;
    }
  }
}

@include sm {
  .header {
    margin-bottom: pxToRem(16);
  }
}

@include md {
  .root {
    .searchForm {
      display: block;
    }

    .search .searchTrigger {
      display: none;
    }
  }
}

@include lg {
  .root {
    padding: pxToRem(24) pxToRem(24) pxToRem(16);
  }
  .footer {
    margin-top: pxToRem(16);
  }
}

@include xl {
  .root {
    padding: pxToRem(32) pxToRem(32) pxToRem(24);
  }
  .footer {
    margin-top: pxToRem(20);
  }
}
