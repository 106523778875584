@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/color.module' as *;

.carDetailsContainer {
  background-color: var(--bac-tie1);
  padding-bottom: pxToRem(16);

  .iconGreenTick {
    position: absolute;
    width: pxToRem(32);
    height: pxToRem(32);
    right: pxToRem(-16);
    top: pxToRem(-16);
    fill: var(--for-suc-bas);
  }
}

.carDetails {
  max-width: max-content;
  margin: auto;
}

.imageContainer {
  position: relative;
  max-width: pxToRem(185);
  margin: auto;
}

.carImage {
  border-radius: var(--cor_rad-xs);
  width: pxToRem(185);
  height: pxToRem(100);
}

.carName {
  margin: pxToRem(12) 0 0;
  @include fontSize(17, 24);
  font-weight: 800;
  color: var(--tex-def-tie1);
  text-align: center;
}

.carInfoContainer {
  display: flex;
  justify-content: center;
}

.carInfo {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.carInfoItem {
  @include fontSize(14, 18);
  margin-right: pxToRem(8);
  color: var(--tex-def-tie2);

  &:before {
    content: '•';
    margin-right: pxToRem(8);
    display: inline-block;
  }

  &:first-child:before {
    content: none;
  }

  &:last-child {
    margin-right: 0;
  }
}

.uspWrapper {
  bottom: pxToRem(228);
  padding: 0 pxToRem(24);
}

.uspContainer {
  display: flex;
  gap: pxToRem(8);
  flex-direction: column;
  padding: pxToRem(24) 0;
  border: pxToRem(1) solid $color-neutral-300;
  border-style: solid none;
}

.usp {
  display: flex;
  align-items: center;
  gap: pxToRem(14);
  @include fontSize(14, 18);
  padding: pxToRem(6) 0;

  .icon {
    width: pxToRem(24);
    height: pxToRem(24);
  }
}

.uspText {
  color: var(--tex-def-tie1);
  flex: 1;
}

.includedText {
  color: var(--for-suc-bas);
  font-weight: 800;
}

.deliveryContent {
  flex: 1;
}

.deliveryText {
  @include fontSize(14, 18);
  font-weight: 400;
  color: var(--tex-def-tie1);
}

.deliveryButtonContainer > button > span {
  padding: 0;
  margin: 0;
  @include fontSize(12, 16);
  color: $color-uiPrimary-600;
  font-weight: 400;
}

.totalContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: pxToRem(28);

  h6 {
    margin: 0;
    @include fontSize(20, 28);
    font-weight: 800;
    color: var(--tex-def-tie1);
  }
}

.priceContainer {
  text-align: right;

  .priceGstLabel {
    margin: 0;
    padding: 0;
    @include fontSize(12, 16);
    color: $color-uiPrimary-600;
    letter-spacing: pxToRem(0.2);
    font-weight: 400;
    cursor: pointer;
  }
}

@include sm {
  .carDetailsContainer {
    position: relative;
    padding: pxToRem(16);
    max-width: pxToRem(400);
    border-radius: var(--cor_rad-md);

    .iconGreenTick {
      right: pxToRem(-16);
      top: pxToRem(-12);
    }
  }

  .carDetails {
    max-width: 100%;
  }

  .imageContainer {
    width: 100%;
    max-width: 100%;
  }

  .carImage {
    width: 100%;
    height: 100%;
  }

  .carName {
    text-align: center;
    @include fontSize(20, 28);
  }

  .carInfo {
    .carInfoItem {
      @include fontSize(15, 20);
    }
  }

  .uspWrapper {
    position: static;
    padding: 0;
  }

  .uspContainer {
    gap: pxToRem(6);
    padding: pxToRem(16) 0;
    margin: pxToRem(16) 0;
  }

  .totalContainer {
    margin: 0;
  }
}
