@use 'src/theme/helpers' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/breakpoints.module' as *;
$gap: 8;
$offset: 20;
// Special carousel styles

.root {
  --carousel-gap: #{pxToRem(8)};
  --carousel-offset: #{pxToRem(20)};

  :global {
    .carouselTrack {
      padding-left: calc(var(--carousel-offset) + var(--carousel-gap));
      padding-right: calc(var(--carousel-offset) + var(--carousel-gap));
      margin-left: calc(-1 * (var(--carousel-offset) + var(--carousel-gap)));
      margin-right: calc(-1 * (var(--carousel-offset) + var(--carousel-gap)));
    }
    .carouselControls {
      .carouselPrev {
        left: calc(#{pxToRem(16)} + (-1 * (var(--carousel-offset)) + var(--carousel-gap)));
      }
      .carouselNext {
        right: calc(#{pxToRem(16)} + (-1 * (var(--carousel-offset)) + var(--carousel-gap)));
      }

      &::before {
        left: calc(-1 * (var(--carousel-offset)));
      }
      &::after {
        right: calc(-1 * (var(--carousel-offset)));
      }
    }
  }
}

@include sm {
  .root {
    :global {
      .carouselControls {
        .carouselPrev {
          left: calc(-1 * (var(--carousel-offset)) + var(--carousel-gap));
        }
        .carouselNext {
          right: calc(-1 * (var(--carousel-offset)) + var(--carousel-gap));
        }

        &::before,
        &::after {
          width: pxToRem(64);
        }

        &::before {
          left: calc(-1 * (var(--carousel-offset) + var(--carousel-gap)));
        }
        &::after {
          right: calc(-1 * (var(--carousel-offset) + var(--carousel-gap)));
        }
      }
    }
  }
}
