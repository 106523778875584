@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.header {
  padding-bottom: pxToRem(32);
}

.summary {
  padding: pxToRem(32) pxToRem(16) 0;
}

@include sm {
  .root {
    :global {
      .carouselTrack {
        gap: #{pxToRem(40)};
      }
    }
  }
  .summary {
    padding-left: pxToRem(32);
    padding-right: pxToRem(32);
  }
}

@include md {
  .header {
    padding-bottom: pxToRem(40);
  }

  .summary {
    max-width: pxToRem(1080);
    margin: auto;
  }
}
