@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  @include sectionVerticalSpacing();
  display: flex;
  flex-direction: column;
  gap: pxToRem(40);
}

.recentSearches {
  flex: 1;
}

.recentlyViewedCarouselWrapper {
  min-width: 0;
}

.header {
  margin-bottom: pxToRem(32);
}

@include md {
  .root {
    display: grid;
    grid-template-columns: pxToRem(344) 1fr;
  }
  .recentSearches {
    min-height: pxToRem(1);
    flex: auto;
  }
  .recentlyViewedCarousel {
    :global(.carouselTrack) {
      margin-left: 0;
      padding-left: 0;
    }
    :global(.carouselControls):before {
      left: 0;
    }
    :global(.carouselControls .carouselPrev) {
      left: pxToRem(16);
    }
  }

  .header {
    margin-bottom: pxToRem(40);
  }
}

@include lg {
  .root {
    grid-template-columns: pxToRem(392) 1fr;
  }
}
