@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  display: flex;
  gap: pxToRem(4);
  margin: pxToRem(16) 0;
  @include fontSize(14, 18);
  letter-spacing: pxToRem(0.2);

  :global(.icon) {
    font-size: pxToRem(22);
    margin-top: pxToRem(-4);
  }
}
