@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  padding-top: pxToRem(32);
  padding-bottom: pxToRem(32);
  @include sectionHorizontalSpacing();
  @include fullWidthBackgroundColor(var(--bac-tie2));
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  row-gap: pxToRem(40);
}

.header {
  padding-bottom: pxToRem(16);
}

.title {
  margin-bottom: pxToRem(22);
}

.item {
  display: grid;
  grid-template:
    '🐱 🐶' pxToRem(56)
    '🐭 🐭' auto / pxToRem(56) auto;
  gap: 1rem;
  border-bottom: $border-0;
  padding-bottom: pxToRem(24);
}

.itemIcon {
  grid-area: 🐱;
  align-self: center;
  width: pxToRem(56);
  height: pxToRem(56);
}

.itemTitle {
  grid-area: 🐶;
  align-self: center;
}

.itemDescription {
  align-self: flex-start;
  grid-area: 🐭;
}

@include sm {
  .root {
    padding-top: pxToRem(48);
    padding-bottom: pxToRem(48);
    grid-template-columns: 1fr 1fr;
    column-gap: pxToRem(20);
    row-gap: pxToRem(44);
  }
}

@include md {
  .root {
    padding-top: pxToRem(56);
    padding-bottom: pxToRem(56);
  }
}

@include lg {
  .root {
    padding-bottom: pxToRem(32);
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: pxToRem(48);
  }

  .item {
    grid-template-rows: pxToRem(80) auto;
    grid-template-columns: pxToRem(80) auto;
    padding-bottom: pxToRem(54);
  }

  .itemIcon {
    width: pxToRem(80);
    height: pxToRem(80);
  }
}

@include xl {
  .root {
    padding-top: pxToRem(80);
    padding-bottom: pxToRem(56);
    row-gap: pxToRem(64);
  }
}
