@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

// Given the size of the counter's root element in px, determine the size of all
// the elements within the counter.
@mixin counterSize($rootSizePx) {
  $circleSizePx: calc($rootSizePx / 1.2);
  $fontSizePx: calc($circleSizePx / 1.5);

  .root {
    width: pxToRem($rootSizePx);
    height: pxToRem($rootSizePx);
  }

  .circle {
    width: pxToRem($circleSizePx);
    height: pxToRem($circleSizePx);
    @include fontSize($fontSizePx, $circleSizePx);
  }
}

.parent {
  counter-reset: count;
}

.root {
  z-index: 1;
  position: absolute;
  left: pxToRem(16);
  top: pxToRem(16);
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  border-radius: 50%;
  text-align: center;

  &:before {
    content: counter(count);
    counter-increment: count;
  }
}

.primary {
  background-color: $color-uiPrimary-700;
  color: var(--tex-inv-tie1);
}

.secondary {
  background-color: var(--bac-tie1);
  color: $color-uiPrimary-000;
}

@include counterSize(32);

@include sm {
  @include counterSize(40);
}

@include xl {
  @include counterSize(48);
}
