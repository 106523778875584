@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  --headerBar-height: #{pxToRem(64)};
  --logo-width: #{pxToRem(72)};

  width: 100vw;
  height: 100dvh;
  background: var(--bac-tie2);
  display: grid;
  grid-template:
    '😀' var(--headerBar-height)
    '👔' 1fr / 1fr;
}

.aside {
  grid-area: 😀;
  position: relative;
}

.asideContent {
  @include rootSection(var(--bac-tie2));
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: var(--logo-width);
  height: var(--headerBar-height);
  display: flex;
  color: $color-brandPrimary-100;

  :global(.carma-logo) {
    width: 100%;
    height: auto;
  }
}

.progress {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  @include rootContainer();
}

.main {
  grid-area: 👔;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.contentWrapper {
  position: relative;
  min-height: calc(100% - var(--footer-height));
}

.content {
  padding: pxToRem(24) pxToRem(16);
  margin: auto;
  width: 100%;
  max-width: pxToRem(800);
}

.footer {
  display: none;
  height: var(--footer-height);
  width: 100%;
  background: var(--bac-tie1);
  box-shadow: 0 0 0 pxToRem(1) var(--color-neutral-150);
}

.footerContent {
  @include rootSection($color-neutral-000);
  @include fontSize(14, 18);
  letter-spacing: pxToRem(0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--footer-height);
  color: var(--tex-def-tie2);
}

.footerLegals {
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    column-gap: pxToRem(20);
    list-style: none;
  }
  li {
    position: relative;

    &:not(:first-child)::before {
      content: '●';
      font-size: 0.7em;
      position: absolute;
      left: pxToRem(-14);
      top: pxToRem(2);
    }
  }
  a {
    font-weight: bold;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

@include md {
  .root {
    --headerBar-height: #{pxToRem(76)};
    --logo-width: #{pxToRem(110)};
  }

  .main {
    --footer-height: #{pxToRem(36)};
  }

  .content {
    padding: pxToRem(40) pxToRem(32);
  }

  .footer {
    display: block;
  }
}
