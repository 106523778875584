@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  @include rootSection($color-neutral-000);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: calc(pxToRem(4) + $headerHeight);
  padding-bottom: pxToRem(4);
  box-sizing: border-box;

  :global {
    .MuiButton-root {
      padding: pxToRem(7) pxToRem(8);
    }

    .MuiButton-label {
      @include fontSize(15, 18);
    }
  }
}

.breadcrumbs {
  @include fontSize(12, 18);
}

@include md {
  .breadcrumbs {
    @include fontSize(14, 18);
  }
}
