@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  color: var(--tex-def-tie1);
}

.tagLabelContainer {
  margin-bottom: pxToRem(16);

  .tagLabel {
    position: static;
    justify-content: center;
  }
}

.title {
  text-align: center;
  margin: 0;
}

.subTitle {
  text-align: center;
  margin: pxToRem(8) 0 0;
}

.content {
  text-align: center;
  margin: pxToRem(16) 0 pxToRem(32);
}

.image {
  &.imageDesktop {
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

@include sm {
  .image {
    &.imageMobile {
      display: none;
    }
    &.imageDesktop {
      display: block;
    }
  }
}

@include md {
  .tagLabelContainer {
    margin-bottom: pxToRem(32);
  }

  .content {
    margin: pxToRem(24) 0 pxToRem(48);
  }
}

@include xl {
  .content {
    margin: pxToRem(24) 0 pxToRem(64);
  }
}
