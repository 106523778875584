@use 'src/theme/helpers' as *;
@use 'src/theme/color.module.scss' as *;

.loading {
  display: flex;
  gap: pxToRem(16);
  align-items: center;
  pointer-events: none;
  padding: pxToRem(10) pxToRem(16);
  color: var(--tex-def-tie3);
}
