@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  --section-paddingTop: #{pxToRem(40)};
  padding-top: calc(var(--section-paddingTop) + $headerHeight);
  padding-bottom: pxToRem(32);
  @include sectionHorizontalSpacing();
  @include rootSection(var(--bac-tie2));
  display: grid;

  :global {
    .HeroContent {
      text-align: left;
    }

    .HeroContent-preHeader {
      margin-bottom: pxToRem(16);
      text-transform: uppercase;
      color: var(--tex-def-tie1);
    }

    .HeroContent-headerContainer {
      margin-bottom: pxToRem(16);
      color: var(--tex-def-tie1);
      text-wrap: balance;
    }

    .HeroContent-subHeader {
      color: var(--tex-def-tie2);
    }

    .HeroContent-ctaContainer {
      display: grid;
      row-gap: pxToRem(16);
      margin: pxToRem(20) 0;
    }

    .HeroContent-cta {
      width: fit-content;
    }
  }
}

.desktopMaskedImage {
  display: none;
}

@include sm {
  .root {
    :global(.HeroContent-ctaContainer) {
      display: flex;
      gap: pxToRem(16);
    }
  }
}

@include md {
  .root {
    grid-template-columns: 50% 50%;
    column-gap: pxToRem(20);

    :global {
      .HeroContent {
        align-content: center;
      }
      .HeroContent-ctaContainer {
        margin: pxToRem(40) 0 0 0;
      }
    }
  }

  .desktopMaskedImage {
    display: block;
  }
  .mobileMaskedImage {
    display: none;
  }
}

@include lg {
  .root {
    grid-template-columns: 45% 55%;
  }
}

@include xl {
  .root {
    grid-template-columns: 50% 50%;
  }
}
