@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  background: var(--bac-tie1);
  box-shadow: var(--elevation-0dp);
  border-radius: var(--cor_rad-lg);
  padding: pxToRem(16);
}

.title {
  @include fontSize(17, 24);
  margin: 0;
  font-weight: bold;
  text-wrap: pretty;
}

.list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: pxToRem(8);
  margin: pxToRem(16) 0 0;
}

.itemTerm {
  @include fontSize(14, 18);
  letter-spacing: pxToRem(0.2);
  color: var(--tex-def-tie2);
}

.itemValue {
  @include fontSize(15, 20);
  margin: 0;
  padding: 0;
}
