@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.footer {
  display: flex;
  justify-content: center;
  padding: pxToRem(32) pxToRem(32) 0;
}

.footerBanner {
  width: 100%;
  display: block;
  background: $color-uiPrimary-050;
  border-radius: var(--cor_rad-xs);
  padding: pxToRem(24);

  :global {
    .MuiButton-root {
      margin-top: pxToRem(16);
      width: 100%;
    }
  }
}

.footerBannerContent {
  @include fontSize(17, 24);
  text-align: center;
}

@include md {
  .footer {
    padding-top: pxToRem(56);
  }

  .footerBanner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: pxToRem(20);

    :global {
      .MuiButton-root {
        margin-top: 0;
        width: fit-content;
      }
    }
  }

  .footerBannerContent {
    text-align: left;
  }
}
