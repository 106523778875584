@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/helpers' as *;

.thankYouRoot {
  width: 100%;
  height: 100%;
}

.content {
  padding: 0;
  max-width: pxToRem(736);
  margin: auto;
}

.image {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: pxToRem(24);
}

.description {
  text-align: center;

  display: flex;
  flex-direction: column;
  row-gap: pxToRem(24);

  h2 {
    @include fontSize(21, 28);
    font-weight: 800;
    margin: 0 0 pxToRem(8);
  }

  p {
    @include fontSize(17, 24);
    color: var(--tex-def-tie2);
  }

  h3 {
    @include fontSize(19, 24);
    margin-top: pxToRem(24);
  }

  ol {
    @include fontSize(17, 24);
    list-style-position: inside;
    color: var(--tex-def-tie2);
    text-indent: -1em;
    padding-left: 1em;
    margin-top: pxToRem(24);
    margin-bottom: pxToRem(24);
  }
  .next {
    margin: 0 auto 0 auto;
    max-width: pxToRem(608);
    padding: 0 pxToRem(16);
    background-color: var(--bac-tie1);
    border-radius: var(--cor_rad-xs);

    ol {
      text-align: left;
    }
  }

  .buttonWrapper {
    display: flex;
    justify-content: center;
  }
}

.meanwile {
  a {
    text-decoration: none;
    font-weight: 700;
    color: var(--tex-def-tie1);
  }
}

.buttonGoHome {
  min-width: 100%;
}

@include sm {
  .buttonGoHome {
    min-width: 346px;
    padding: pxToRem(16) pxToRem(20);
  }
  .content {
    padding: 0 pxToRem(24);
  }

  .description {
    text-align: center;

    display: flex;
    flex-direction: column;
    row-gap: pxToRem(24);

    h2 {
      @include fontSize(24, 28);
    }

    h3 {
      @include fontSize(20, 28);
    }
  }
}
