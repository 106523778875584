// Global styles for the shade

.shade_container {
  > :not(.shade) {
    pointer-events: none;
  }

  &--grey {
    --shade-gradient-col1: var(--sta-ter-hov);
    --shade-gradient-col2: var(--sta-ter-def);
  }
}
