@use 'src/theme/helpers' as *;
@use 'src/theme/color.module' as *;

.stars {
  display: flex;
  gap: pxToRem(8);
  align-items: center;
  padding: 0 pxToRem(4) pxToRem(4);
  font-size: pxToRem(16);

  :global(.icon) {
    font-size: inherit;
  }

  .starFull {
    color: $color-star;
  }
  .starEmpty {
    color: $color-neutral-300;
  }
}

.starWrapper {
  position: relative;
  height: 1em;
}

.starWrapperFull {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
}
