@use 'src/theme/helpers' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/breakpoints.module' as *;

.root {
  position: relative;
}

.track {
  /* snap mandatory on horizontal axis  */
  scroll-snap-type: x mandatory;
  overflow-y: hidden;
  display: flex;
  align-items: stretch;
  gap: pxToRem(16);
  /* Enable Safari touch scrolling physics which is needed for scroll snap */
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* Hide scrollbar on IE and Edge */
  scrollbar-width: none; /* Hide scrollbar on Firefox */
  padding: pxToRem(24) pxToRem(8); /* Padding around the tiles to allow for the box shadow to show */
  margin: pxToRem(-24) pxToRem(-8);

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar on Chrome, Safari and Opera */
  }

  &:global(.overflow-right) {
    + .controls {
      &:after,
      .next {
        opacity: 1;
      }
    }
  }

  &:global(.overflow-left) {
    + .controls {
      &:before,
      .prev {
        opacity: 1;
      }
    }
  }
}

.controls {
  position: static;
  pointer-events: none;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: pxToRem(-2);
    height: 100%;
    width: pxToRem(36);
  }

  &:before,
  &:after,
  .next,
  .prev {
    transition: opacity 200ms ease-in-out;
    opacity: 0;
    cursor: pointer;
  }

  &:before {
    left: pxToRem(-2);
    // Using straight black and white for the
    background: linear-gradient(
      90deg,
      var(--section-background, #{$color-neutral-000}) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &:after {
    right: pxToRem(-2);
    // Using straight black and white for the
    background: linear-gradient(
      270deg,
      var(--section-background, #{$color-neutral-000}) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .next,
  .prev {
    display: none;
  }
}

.tile {
  scroll-snap-align: center;
  flex: 0 0 pxToRem(300);
  min-height: 100%;
}

@include sm {
  .tile {
    flex: 0 0 pxToRem(320);
  }
}

@include md {
  .tile {
    flex: 0 0 pxToRem(352);
  }

  .controls {
    &:before,
    &:after {
      width: pxToRem(72);
    }

    .next,
    .prev {
      z-index: 1;
      display: block;
      position: absolute;
      top: calc(50% - #{pxToRem(20)});
      pointer-events: auto;
    }

    .prev {
      left: pxToRem(8);
    }

    .next {
      right: pxToRem(8);
    }
  }
}
