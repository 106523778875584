@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  --section-paddingTop: #{pxToRem(40)};
  display: grid;
  grid-template-columns: 1fr;
  row-gap: pxToRem(8);

  &:global(.c-se) {
    padding-top: calc(var(--section-paddingTop) + $headerHeight);
  }
}

.header {
  .subTitle {
    margin-top: pxToRem(8);
  }
}
.header,
.imageWrapper {
  margin-bottom: pxToRem(8);
}

.imageWrapper {
  position: relative;
  container-type: inline-size;
  isolation: isolate;
  overflow: hidden;
  border-radius: var(--cor_rad-lg);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(11, 5, 29, 0) 61.35%, rgba(11, 5, 29, 0.8) 100%);
    pointer-events: none;
  }
}

.lenderContainer {
  position: absolute;
  left: pxToRem(24);
  right: pxToRem(24);
  bottom: pxToRem(12);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--tex-inv-tie1);
  z-index: 1;
}

.lenderIntroContainer {
  display: none;
}

.footer {
  :global(.valueProps) {
    margin-top: pxToRem(20);
  }
}

.note {
  color: var(--tex-def-tie3);
}

@include md {
  .root {
    --section-paddingTop: #{pxToRem(48)};
    grid-template:
      '😀 🎛️' auto
      '🚗 🎛️' 1fr
      '👠 👠' auto / 1fr 1fr;
    row-gap: pxToRem(16);
    column-gap: pxToRem(16);
  }

  .header {
    grid-area: 😀;
    margin-bottom: 0;
  }

  .imageWrapper {
    grid-area: 🚗;
    margin-bottom: 0;

    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .form {
    grid-area: 🎛️;
    min-height: pxToRem(636);
  }

  .footer {
    grid-area: 👠;

    :global(.valueProps) {
      margin-top: pxToRem(32);
    }
  }
}

@include mdp {
  .root {
    column-gap: pxToRem(32);
  }
}

@include lg {
  .root {
    column-gap: pxToRem(56);
  }
}

@container (min-width: #{pxToRem(580)}) {
  .lenderIntroContainer {
    display: block;
    max-width: pxToRem(156);
    padding-right: pxToRem(16);
    margin-right: pxToRem(14);
    border-right: thin solid;
  }
}
