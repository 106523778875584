@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  --border-color: var(--bor-neu-bas);
  --height: #{pxToRem(20)};

  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: pxToRem(8) 0;
  font-size: pxToRem(15);
  line-height: var(--height);
  height: var(--height);
  font-weight: 400;
}

.content {
  position: relative;
  height: var(--height);

  &:empty {
    width: 100%;

    &:before {
      left: 0;
      width: 100%;
    }

    &:after {
      content: none;
    }
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: calc(50% - pxToRem(-1));
    width: 50vw;
    border-top: pxToRem(1) solid var(--border-color);
  }

  &:before {
    right: calc(100% + pxToRem(8));
  }

  &:after {
    left: calc(100% + pxToRem(8));
  }
}
