@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  border-top: $border-0;
  background: var(--bac-tie1);
  position: relative;
}

.container {
  @include rootSection();
}

.contact {
  text-align: center;
  padding: pxToRem(72) 0 pxToRem(60);
  border-bottom: $border-0;
}

.contactHeader {
  margin-bottom: pxToRem(24);
}

.contactContent {
  margin-top: pxToRem(8);
  color: var(--tex-def-tie2);
}

.contactLinksWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: pxToRem(16);
  margin: pxToRem(24) auto 0;
  width: 100%;
}

.contactLink {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template:
    '😀 🚗' auto
    '✅ 🚗' auto / 1fr pxToRem(56);
  column-gap: pxToRem(16);
  row-gap: pxToRem(4);
  padding: pxToRem(18) pxToRem(16);
  text-align: left;
  color: var(--tex-def-tie1);
  text-decoration: none;
  background: var(--for-neu-tie1);
  border: $border-0;
  box-shadow: var(--elevation-2dp);
  border-radius: var(--cor_rad-lg);
  transition: all ease-in-out 200ms;
  position: relative;
  overflow: hidden;

  &:hover,
  &:focus-visible {
    box-shadow: var(--elevation-12dp);
  }

  :global(.shade:before) {
    display: none;
  }
}

.contactLinkTitle {
  grid-area: 😀;
  pointer-events: none;
  position: relative;
}

.contactLinkDescription {
  grid-area: ✅;
  pointer-events: none;
  position: relative;
}

.contactLinkIcon {
  grid-area: 🚗;
  pointer-events: none;
  position: relative;
  background: var(--for-neu-tie2);
  border-radius: var(--cor_rad-ful);
  align-self: center;
  font-size: pxToRem(40);
  width: 1em;
  height: 1em;
  padding: pxToRem(8);
  box-sizing: content-box;

  :global(.icon) {
    display: block;
    font-size: inherit;
  }
}

.linksContainer {
  padding: pxToRem(48) 0 pxToRem(40);
  border-bottom: $border-0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  column-gap: pxToRem(24);
  row-gap: pxToRem(48);
}

.linksHeader {
  margin-bottom: pxToRem(24);
}

.linksTitle {
  color: var(--tex-def-tie2);
}

.linksList {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  row-gap: pxToRem(16);
}

.linksListLink {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }
}

.awardsContainer {
  border-bottom: $border-0;
}

.licencesContainer {
  padding: pxToRem(48) 0;
  .licences {
    margin: auto;
  }
}

.licences {
  max-width: pxToRem(1060);
  color: var(--tex-def-tie2);

  p {
    margin-bottom: pxToRem(12);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.legalsContainer {
  display: flex;
  flex-direction: column;
  row-gap: pxToRem(8);
  column-gap: pxToRem(16);
  padding: pxToRem(28) 0;
  color: var(--tex-def-tie2);
}

.legalsLinks {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  column-gap: pxToRem(8);
}

.legalsLinkItem:not(:first-child) {
  &:before {
    content: '•';
    display: inline-block;
    margin-right: pxToRem(8);
  }
}

.legalsLink {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }
}

@include md {
  .contactLink {
    max-width: pxToRem(320);
  }

  .linksContainer {
    padding: pxToRem(56) 0;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .licences {
    text-align: center;
  }

  .legalsContainer {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

@include mdp {
  .contactLink {
    max-width: pxToRem(320);
  }

  .linksContainer {
    padding: pxToRem(56) 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .linksItem {
    width: calc(20% - #{pxToRem(16)});
    max-width: pxToRem(240);
  }
}
