@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.header {
  margin-bottom: pxToRem(32);
}

.tile {
  position: relative;
  overflow: hidden;
  border-radius: var(--cor_rad-lg);
  border: $border-0;
  box-shadow: var(--elevation-2dp);
  transition: box-shadow 200ms ease-in-out;
  padding: pxToRem(12);

  &.link {
    &:hover,
    &:focus-visible {
      box-shadow: var(--elevation-12dp);
    }
  }

  :global(.shade::before) {
    content: none;
  }
}

.link {
  display: block;
  color: inherit;
  text-decoration: none;
}

.image,
.content {
  pointer-events: none;
}

.image {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 4/5;
  object-fit: cover;
  border-radius: var(--cor_rad-xs);
}

.content {
  padding: pxToRem(16) 0 pxToRem(8);
}

.tileDescription {
  color: var(--tex-def-tie2);
  margin: 0;

  .tileTitle + & {
    margin-top: pxToRem(8);
  }
}

@include md {
  .header {
    margin-bottom: pxToRem(40);
  }
  .content {
    padding: pxToRem(16) pxToRem(4);
  }
}

@include mdp {
  .content {
    padding: pxToRem(16) pxToRem(12);
  }
}
