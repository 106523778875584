@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/zindexes.module' as *;

.root {
  padding-top: pxToRem(8);
  padding-bottom: pxToRem(8);

  + .root {
    padding-top: 0;
  }
}

.wrapper {
  background: var(--bac-tie1);
  box-shadow: var(--elevation-0dp);
  border-radius: var(--cor_rad-lg);
  isolation: isolate;
  position: relative;
}

.summary {
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  padding: pxToRem(16);
  position: relative;
  z-index: 1;
  background: var(--bac-tie1);
  border-radius: var(--cor_rad-lg);

  &::-webkit-details-marker {
    display: none;
  }
}

.summaryOpen .icon {
  transform: rotate(-180deg);
}

.icon {
  transition: transform ease-in-out 200ms;
}

.content {
  padding: pxToRem(8);
}

@include md {
  .root {
    padding-top: pxToRem(24);
    padding-bottom: pxToRem(24);
  }
}
